@import url("https://fonts.googleapis.com/css2?family=Lexend+Exa&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600&display=swap");
html {
  box-sizing: border-box;
}

html,
body,
#root {
  padding: 0px !important;
  margin: 0px !important;
  height: 100vh;
  width: 100vw;
  /* overflow-x: hidden; */
  /* cursor: url("./Cursor.png"), auto; */
  overflow: -moz-scrollbars-none;
}

body::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.head {
  font-size: 5rem;
  background-color: #290834;
  /* font-family: 'Lexend Exa', sans-serif; */
  display: flex;
  font-weight: semi-bold;
  justify-content: center;
  align-items: center;
  color: white;
  padding-top: 3rem;
}

@media (min-width: 800px) {
  .head {
    font-size: "3rem";
  }
}
