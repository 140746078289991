.main{
    width: 100vw;
    height: fit-content;
    padding-top: 1.5rem;
    
    /* background: linear-gradient(180deg, #450834 12.5%, #290329 72.92%); */
    background: linear-gradient(10deg,#01091e, #01091e, #00ffff);
    /* background: url('./bg1.jpg'); */
    background-size: cover;
    /* border: 1px solid #000000; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.heading{

    font-family: 'Lexend Exa';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 125px;

    margin-top: auto;

    color: #FFFFFF;

    margin-left: 5rem;
}

.wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    align-content: space-around;
}



.box1,.box2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: space-around;

    width: 35rem;
    
}
/* .box2{
    margin-left: 5rem;
} */

/* @media screen and (max-width: 768px) {
    .main{
        height: 100vh;
    }
    .wrapper{
        flex-direction: column;
    }
    .box1,.box2{
        width: 100%;
    }
    .box2{
        margin-left: 0;
    }
} */

@media (max-width: 800px){
    .main{
        min-height: 100vh;
    }
    .heading{
        margin-left: 1rem;
        line-height: 2.5rem;
        font-size: 1.5rem;
    }
    .wrapper{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .box1,.box2{
        width: 90%;
        /* margin-left: 10px; */
        /* margin-right: 100px; */
    }
}

@media (max-width: 1100px) and (min-width:801px){
    .main{
        height: 160vh;
    }
    .heading{
        margin-top: 3rem;
        margin-left: 7rem;
        line-height: 2.5rem;
        font-size: 2.5rem;
    }
    .wrapper{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        
    }

    .box1,.box2{
        width: 90%;
        
    }
    
    
}
