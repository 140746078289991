
.sponcercard{
    padding: 3%;
    max-width: 330px;
    width: 80%;
    /* background: transparent; */
    /* box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem; */
    /* position: relative; */
    z-index: 1;
    /* aspect-ratio: 1; */
    /* filter: saturate(0.6),blur(10); */
}
.tcardhoverbox{
  transform: scale(0.9);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.tcardhoverbox:hover{
  transform: scale(1);
}
.cardhoverbox{
  z-index: 1;
    /* aspect-ratio: 1; */
    /* filter: saturate(0.6),blur(10); */
    --g: 2px;     /* the gap */
    --b: 3px;    /* border thickness*/
    --c: transparent; /* the color */
    padding: calc(var(--g) + var(--b));
    --_c: #0000 0 25%, var(--c) 0 50%;
    --_g1: repeating-linear-gradient(90deg ,var(--_c)) repeat-x;
    --_g2: repeating-linear-gradient(180deg,var(--_c)) repeat-y;
    background:
      var(--_g1) var(--_p, 25%) 0   ,var(--_g2) 0    var(--_p,125%),
      var(--_g1) var(--_p,125%) 100%,var(--_g2) 100% var(--_p, 25%);
    background-size: 200% var(--b),var(--b) 200%;
    transition: .3s;
}
.cardhoverbox:hover{
  --_p: 75%;
    filter: grayscale(0%);
    --c: #00ffe5;     
}

  
/* img {
    
  } */
  .sponcercard:hover {
    --_p: 75%;
    filter: grayscale(0%);
    --c: #00ffe5; 
  }
  
