.wrapper{
    width:35rem ;
    /* margin-left: 5rem; */
    
}

@media (max-width: 800px){
    .wrapper{
        width: 100%;
        margin-left: 0;
    }
}


@media (max-width: 1100px) and (min-width:801px){
    .wrapper{
        width: 100%;
    }
}
/* 
@media (max-width: 1100px){
    .wrapper{
        width: 60rem;
        margin-left: 0;
    }
} */